<script setup lang="ts">
import { getBrand } from "~/advertisorsData/shared/getBrand";
import type { Brand, BrandName } from "~/advertisorsData/shared/types";

const learnMoreModalVisible = ref(false);
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const brand = ref<Brand>();

interface Props {
  brandName: BrandName;
}

const props = defineProps<Props>();

const showInvitationSection = computed(() => {
  return route.query?.publicToken;
});

function checkAndSaveVtidInRoute() {
  if (typeof route.query?.vtid === "string") {
    localStorage.setItem("vtid", route.query.vtid);

    // Clear the route query params
    router.push({ query: {} });
  }
}

function trackEvents() {
  const vtid = localStorage.getItem("vtid");

  trackEvent("landing_page_viewed", {
    brand: props.brandName,
    advertiser: props.brandName,
    invited: showInvitationSection.value ? true : false,
    ...(vtid && { walletHash: vtid }),
  });
}

onMounted(async () => {
  brand.value = await getBrand(props.brandName);

  document.documentElement.style.setProperty(
    "--body-landing-bg",
    brand.value.designSchema.bgColor,
  );

  checkAndSaveVtidInRoute();
  trackEvents();

  if (route.query?.access_token) {
    loading.value = true;
  }

  clearTokens();
  setTimeout(() => {
    initGateway();
  }, 500);
});

definePageMeta({ layout: "blank" });

useHead({ bodyAttrs: { class: "body--advertisor-landing" } });
</script>

<template>
  <Modal
    v-if="learnMoreModalVisible"
    class="modal--content"
    @close="learnMoreModalVisible = false"
  >
    <ContentsHowDoesItWorksSlider />
    <div class="text-center mt-8">
      <button
        class="btn bg-purple-light hover:bg-black hover:text-white"
        @click="learnMoreModalVisible = false"
      >
        Done
      </button>
    </div>
  </Modal>

  <AdvertiserLandingHeader v-if="brand" :brand="brand" />

  <PageLoader v-if="loading" />
  <div v-else-if="brand">
    <AdvertiserLandingInvitationContent
      v-if="showInvitationSection"
      :brand="brand"
    />
    <AdvertiserLandingCommonContent v-else :brand="brand" />
    <div class="container-950 mt-10 lg:mt-14">
      <div
        class="bg-white relative overflow-hidden p-6 pt-1 rounded-2xl font-semibold"
      >
        <svg
          class="absolute hidden md:block top-0 right-[-2px] z-0"
          width="536"
          height="280"
          viewBox="0 0 536 280"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.4" filter="url(#filter0_i_1279_667)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M323.5 480C144.836 480 -7.80966e-06 335.164 0 156.5C7.80966e-06 -22.1642 144.836 -167 323.5 -167C502.164 -167 647 -22.1641 647 156.5C647 335.164 502.164 480 323.5 480ZM578.895 156.5C578.895 274.671 498.638 374.096 389.658 403.243C377.031 406.62 365.27 396.616 365.27 383.545L365.263 362.364L365.255 156.507L365.27 148.04C365.27 129.967 350.128 115.316 331.45 115.316C312.771 115.316 297.629 129.967 297.629 148.04V231.872C297.629 252.907 277.712 268.547 259.929 257.312C226.51 236.199 204.316 198.931 204.316 156.479C204.316 90.6552 257.676 37.2946 323.499 37.2946C389.323 37.2946 442.684 90.6553 442.684 156.479C442.684 157.98 442.656 159.475 442.601 160.962V231.242C442.601 259.058 473.497 272.043 487.074 247.766C502.179 220.758 510.789 189.624 510.789 156.479C510.79 53.0418 426.937 -30.8106 323.499 -30.8106C220.062 -30.8106 136.21 53.0417 136.21 156.479C136.21 241.194 192.455 312.772 269.639 335.908C285.376 340.625 297.629 354.232 297.629 370.661V374.227C297.629 393.698 280.714 409.083 261.815 404.396C150.565 376.804 68.1054 276.282 68.1055 156.5C68.1055 15.4494 182.449 -98.8947 323.5 -98.8947C464.551 -98.8947 578.895 15.4494 578.895 156.5Z"
              fill="#D9D9D9"
              fill-opacity="0.01"
            />
          </g>
          <defs>
            <filter
              id="filter0_i_1279_667"
              x="-16"
              y="-203"
              width="663"
              height="683"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-16" dy="-36" />
              <feGaussianBlur stdDeviation="22" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.534277 0 0 0 0 0.534277 0 0 0 0 0.534277 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_1279_667"
              />
            </filter>
          </defs>
        </svg>
        <div class="mt-4 flex gap-1 items-center">
          Introducing
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 16C3.58172 16 -5.4282e-07 12.4183 -3.49691e-07 8C-1.56562e-07 3.58172 3.58172 -5.4282e-07 8 -3.49691e-07C12.4183 -1.56562e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM14.3158 8C14.3158 10.9223 12.3311 13.381 9.63605 14.1018C9.32379 14.1853 9.03295 13.938 9.03295 13.6147L9.03295 13.0911C9.03295 13.091 9.03286 13.0909 9.03277 13.0909C9.03267 13.0909 9.03258 13.0908 9.03258 13.0907L9.03258 8.00018C9.03258 8.00008 9.03267 8 9.03277 8C9.03287 8 9.03295 7.99992 9.03295 7.99982L9.03295 7.79079C9.03295 7.34385 8.65849 6.98153 8.19658 6.98153C7.73467 6.98153 7.36022 7.34385 7.36022 7.79079L7.36022 9.86391C7.36022 10.3841 6.86769 10.7709 6.42792 10.493C5.60149 9.97091 5.05262 9.04929 5.05262 7.99948C5.05262 6.37169 6.3722 5.05211 7.99999 5.05211C9.62778 5.05211 10.9474 6.37169 10.9474 7.99948C10.9474 8.0366 10.9467 8.07356 10.9453 8.11035L10.9453 9.84834C10.9453 10.5362 11.7093 10.8573 12.0451 10.257C12.4187 9.58906 12.6316 8.81913 12.6316 7.99948C12.6316 5.44153 10.5579 3.3679 7.99999 3.3679C5.44204 3.3679 3.36841 5.44153 3.36841 7.99948C3.36841 10.0944 4.75931 11.8645 6.66804 12.4367C7.05722 12.5533 7.36022 12.8898 7.36022 13.2961L7.36022 13.3843C7.36022 13.8658 6.94191 14.2462 6.47456 14.1303C3.72341 13.448 1.68421 10.9621 1.68421 8C1.68421 4.51189 4.51189 1.68421 8 1.68421C11.4881 1.68421 14.3158 4.51189 14.3158 8Z"
              fill="black"
            />
          </svg>
          Evangelist
        </div>
        <h2 class="text-2xl mt-8">Become a thought leader in your niche.</h2>

        <p class="mt-3">And get paid while doing that.</p>
        <div
          class="flex flex-wrap gap-4 items-center justify-between mt-10 lg:mt-16 relative z-10"
        >
          <div class="">
            Evangelist helps connect product evangelists with builders while<br />leveraging
            Solana blockchain for maximum rewarding transparency.
          </div>
          <button
            class="mt-6 lg:mt-0 btn hover:bg-black text-white text-sm"
            :style="{
              backgroundColor: brand.designSchema.primaryColor,
              borderRadius: brand.designSchema.borderRadius,
              color: brand.designSchema.primaryColorContrast,
            }"
            @click="learnMoreModalVisible = true"
          >
            Learn more
          </button>
        </div>
      </div>
    </div>
    <footer class="mt-8 mb-14">
      <div class="container-950 flex justify-between items-center gap-8">
        <div
          class="fontsemibold text-xs"
          :class="[brand.designSchema.darkMode ? 'text-white ' : '']"
        >
          2024 &copy; Evangelist + &copy; {{ brand.name }}
        </div>
        <EvangelistAmbasador
          :theme-mode="brand.designSchema.darkMode ? 'dark' : 'light'"
        />
      </div>
    </footer>
  </div>
</template>
